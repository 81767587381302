<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Search Products</h4>
            </b-col>
            <b-col class="text-right">
              <b-button variant="primary" size="sm" squared @click="addProduct">Add Product</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Referance Number</label>
              <b-form-input v-model="product.productRef"></b-form-input>
            </b-col>
            <b-col cols="4">
              <label>Date Created From</label>
              <b-form-datepicker v-model="product.dateLoggedFrom"></b-form-datepicker>
            </b-col>
            <b-col cols="4">
              <label>Date Created To</label>
              <b-form-datepicker v-model="product.dateLoggedTo"></b-form-datepicker>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Item Code</label>
              <b-form-input v-model="product.itemCode"></b-form-input>
            </b-col>
          </b-row>
          <hr class="mx-3">
          <b-row>
            <b-col class="text-right">
              <b-button variant="red" class="mx-3" squared @click="clearSearch" >Cancel</b-button>
              <b-button variant="primary" squared @click="searchResults" >Search</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <div class="mt-3">
      <b-row>
        <b-col class="12">
          <b-card>
            <b-row>
              <b-table striped hover 
                       :items="tableData.dataSource" 
                       :fields="tableData.tableColumns"
                       :busy="tableData.isLoading" 
                       @row-clicked="openProduct"
                       :per-page="tableData.resultsPerPage"
                       id="stockTable"
                       :current-page="tableData.currentPage" sort-icon-left>

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>

                <template #cell(createdDate)="row">
                  <b-row align-v="center">
                    <span class="mr-auto">{{row.item.createdDate | dateTimeFilter}}</span>
                  </b-row>
                </template>

                <template #cell(actions)="row">
                  <b-row @click="openProduct(row.item)" align-v="center" align-h="end">
                    <b-button size="sm" class="btn-icon">
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
            </b-row>
            <b-row align-h="center" >
              <b-pagination
                  v-model="tableData.currentPage"
                  :total-rows="rows"
                  :per-page="tableData.resultsPerPage"
                  aria-controls="stockTable"
              ></b-pagination>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
    name: 'StockSearch',
    data: () => ({
      product: {
        priceExVat: null,
        itemCode: null,
        productRef: null,
        description: null,
        dateLoggedFrom: null,
        dateLoggedTo: null,
      },
      tableData: {
        resultsPerPage: 10,
        currentPage: 1,
        dataSource: [],
        isLoading: true,
        tableColumns: [
          {
            label: 'Item Code',
            key: 'itemCode',
            sortable: true,
            tdClass: '',
          },
          {
            label: 'Product Reference',
            key: 'productRef',
            sortable: true,
            tdClass: '',
          },
          {
            label: 'Date Created',
            key: 'createdDate',
            sortable: true,
            tdClass: '',
          },
          {
            label: '',
            key: 'actions',
            sortable: false,
            tdClass: ''
          }
        ]
      },
    }),
    created(){
      this.searchResults()
      this.setBreadcrumb([
        {
          text: 'Product'
        },
      ])
    },
  
    methods:{
      ...mapActions(['searchProducts']),
      ...mapMutations('breadcrumbService', ['setBreadcrumb']),

      searchResults() {
        
        this.tableData.isLoading = true
        let request = []
        
        if (this.product.productRef != null){
          request.push({"key": "productRef", "value": this.product.productRef})
        }
        if (this.product.dateLoggedFrom != null){
          request.push({"key": "createdDateFrom", "value": this.product.dateLoggedFrom})
          //console.log('DATE FROM', this.product.dateLoggedFrom)
        }
        if (this.product.dateLoggedTo != null){
          request.push({"key": "createdDateTo", "value": this.product.dateLoggedTo})
          //console.log('DATE TO', this.product.dateLoggedTo)
        }
        if (this.product.itemCode != null) {
          request.push({'key': "itemCode", 'value': this.product.itemCode})
        }

        this.$store.commit('setProductSearchRequest', request)
        
        this.searchProducts()
            .then((request) => {
              this.tableData.dataSource = request.data
              this.tableData.isLoading = false
            })
            .catch( () => {
              this.tableData.isLoading = false
            })
      },
      
      openProduct(product){
        this.$store.commit('setSelectedProduct', product)
        this.$router.push({path: '/admin/stockView'})
      },
      
      addProduct() {
        this.$router.push({path: '/admin/createProduct'})
      },
      
      clearSearch() {
        this.product = {
          productRef: null,
          itemCode: null,
          dateLoggedFrom: null,
          dateLoggedTo: null,
        }
        this.searchResults()
      },
    },
      
    computed:{
        rows() {
            return this.tableData.dataSource.length
        }
    },
}
</script>
